import React from "react"
import {Row} from 'react-bootstrap'

// images
import banner_img from '../images/banner.jpg'

const featured = () => (
    <div className="row align-items-center my-5 bg-white rounded border-light-grey drop-shadow-small">
        
        <Row>
            <div className="col-lg-6">
                <img className="img-fluid mx-auto" 
                    src={banner_img} 
                    alt="Carousel Hat Banner Image" />   
            </div>
            <div className="col-lg-6">
                <div class="container color-dark-grey mb-4">
                <h1 className="font-weight-heavy color-dark-grey pt-4 mx-auto">Welcome to Carousel Hat!</h1>
                    <p class="color-dark-grey pr-5 pl-3">
                        Welcome to Carousel Hat! Dave, Lisa, and the rest of our team have ambitions that go far beyond just sales. 
                        We back up our work and always try to go the extra mile to ensure the best experience possible.
                    </p>
                    <br />
                </div>
            </div>
        </Row>
    </div>
)

export default featured
