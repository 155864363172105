import React from "react"
import { Link } from "gatsby"

const serviceCard = ({ img, serviceName, children }) => (

    <div className="col-lg-4 col-md-6 mb-4">
        <div className="card h-100 m-0 bg-light drop-shadow-small">

            <div className="card-footer text-cente m-0 bg-medium-dark">
                <h4 className="card-title text-center color-white"> {serviceName} </h4>
            </div>

            <div class="container">
                <div className="card-body">
                    <div className="row justify-content-center">
                        <img className="border-light-grey img-fluid text-align-center rounded mb-4 mb-lg-0" src={img} alt={serviceName} />
                    </div>
                    <br />
                    <div className="justify-content-center">
                        <p className="card-text color-dark-grey"> {children} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default serviceCard