import * as React from "react"
import { Link } from "gatsby"
import {Container, Row, Col} from 'react-bootstrap'

import Layout from "../components/layout"
import Seo from "../components/seo"
import ServiceCard from '../components/service-card'
import Featured from '../components/featured'

// images
import screenPrint from "../images/services/screenPrint.jpg"
import stickers from "../images/services/stickers.jpg"
import banners from "../images/services/banners.jpg"
import livePrint from "../images/services/livePrint.jpg"
import promotional from "../images/services/promotional.jpg"
import vinylWraps from "../images/services/vinylWraps.jpg"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <Featured />

    <Row>
      <ServiceCard img={screenPrint} serviceName="Screen Printing"> Custom printed t-shirts, hoodies, tote bags, and so much more! We take pride in exceptional quality screen prints that will make you and your brand stand out from the rest! </ServiceCard>
      <ServiceCard img={stickers} serviceName="Stickers & Decals"> Stickers are a great way to get your brand out there! From custom cut stickers to vehicle decals, our large format printer and cutter have you covered! </ServiceCard>
      <ServiceCard img={banners} serviceName="Banners & Signs"> Need a new sign or banner for your business or event? Leave it to us! <Link to="./contact"> Contact us </Link> for pricing and details. </ServiceCard>

      <ServiceCard img={livePrint} serviceName="Live Screen Printing"> Let us screen print live at your event to create an unparalleled experience for your customers! </ServiceCard>
      <ServiceCard img={promotional} serviceName="Promotional Items">Put your brand on a wide variety of promotional products such as USB thumb drives, golf balls, mousepads, and so much more!</ServiceCard>
      <ServiceCard img={vinylWraps} serviceName="Vinyl Wraps">We use quality vinyl from reputable vendors to wrap business and personal vehicles as well as trailers. <Link to="./contact"> Contact us </Link> to find out more! </ServiceCard>
    </Row>
  </Layout>
)

export default IndexPage